<script setup lang="ts" generic="T extends DropdownValue, E extends DropdownItem">
  import { inject, shallowRef, type Ref } from 'vue';
  import { useDropdown, type DropdownItem, type DropdownValue, type DropdownProps, type DropdownState } from '.';

  const value = defineModel<T | T[] | null | undefined>('value', { required: false });

  const state = defineModel<DropdownState<T | T[] | null | undefined>>('state', { required: false });

  const props = defineProps<DropdownProps<E>>();

  const detailsElement = inject<Ref<HTMLDetailsElement | null>>('detailsElement', shallowRef(null));

  const { filtered, includes, selectOption } = useDropdown(value, state, props, detailsElement);

  const eventMap = shallowRef<{ [key: string]: (event: KeyboardEvent) => void }>({
    ArrowDown: (event) => {
      event.preventDefault();
      if (filtered.value) state.value!.focused = (state.value!.focused + 1) % filtered.value.length;
    },
    ArrowUp: (event) => {
      event.preventDefault();
      if (filtered.value) state.value!.focused = (state.value!.focused - 1 + filtered.value.length) % filtered.value.length;
    },
    Enter: () => {
      if (filtered.value && state.value!.focused >= 0) {
        selectOption(filtered.value[state.value!.focused]);
      }
      detailsElement.value?.removeAttribute('open');
    },
    Escape: () => {
      detailsElement.value?.removeAttribute('open');
    },
  });

  function handleKeyDown(event: KeyboardEvent) {
    eventMap.value[event.key]?.(event);
  }
</script>

<template>
  <slot v-bind="{ filtered, includes, handleKeyDown, selectOption }"></slot>
</template>
