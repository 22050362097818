<script setup lang="ts" generic="T extends DropdownValue, E extends DropdownItem">
  import { computed, provide, ref } from 'vue';

  import Wrapper from './Wrapper.vue';

  import type { DropdownItem, DropdownValue, DropdownProps, DropdownState } from '.';

  const value = defineModel<T | T[] | null>('value', { required: false });
  const selected = defineModel<T | T[] | null>('selected', { required: false });

  const props = withDefaults(defineProps<DropdownProps<E>>(), {
    searchable: true,
    placeholder: 'selecteer',
    options: () => [],
  });

  const detailsElement = ref<HTMLDetailsElement | null>(null);

  const selectedValue = computed(() => {
    if (Array.isArray(value.value)) {
      return value.value.map((opt: DropdownValue) => props.options?.find((option) => option.value === opt)?.name).join(', ');
    } else {
      return props.options?.find((option) => option.value === value.value)?.name;
    }
  });

  const state = ref<DropdownState<T | T[] | null | undefined>>({
    focused: -1,
    selected: selected.value,
    search: '',
  });

  provide('detailsElement', detailsElement);
</script>

<template>
  <Wrapper v-slot="{ filtered, handleKeyDown, selectOption, includes }" v-model:value="value" v-model:state="state" :options="options" v-bind="$attrs">
    <fieldset :class="`dropdown ${$attrs.class}`" v-outside="() => detailsElement?.removeAttribute('open')" :disabled>
      <label v-if="label">{{ label }}</label>
      <details ref="detailsElement" @keydown="handleKeyDown">
        <summary @input="(event: Event) => (state.search = (event.target as HTMLInputElement)?.textContent)" :contenteditable="searchable" tabindex="0" :data-placeholder="placeholder" :class="inputClass">
          {{ selectedValue ?? state.selected }}
        </summary>
        <ul>
          <template v-for="(item, index) in filtered" :key="index">
            <li :class="[includes(item?.value) ? '-selected' : '', state.focused === index ? '-focused' : '']" @click.prevent="selectOption(item)">
              {{ item?.name }}
            </li>
          </template>
        </ul>
      </details>
    </fieldset>
  </Wrapper>
</template>
